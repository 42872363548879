import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import PhotoVideoLibrary from "./pages/PhotoVideoLibrary";
import DocumentManagement from "./pages/DocumentManagement";
import Login from "./components/Login";
import { useAuth } from "./context/Context";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/library" element={<PhotoVideoLibrary />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/manage"
        element={
          <ProtectedRoute>
            <DocumentManagement />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
