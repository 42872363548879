import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import Hamburger from "./Hamburger";
import { useAuth } from "../context/Context";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignInClick = () => {
    if (isAuthenticated) {
      logout();
    } else {
      navigate("/login");
    }
    setIsMenuOpen(false); // Close the menu after clicking
  };

  const menuItems = ["Home", "About", "Services", "Contact", "Library"];

  const wrapLetters = (text: string) => {
    return text.split("").map((char, index) => (
      <span key={index} className={styles.flyingLetter}>
        {char}
      </span>
    ));
  };

  useEffect(() => {
    const links = document.querySelectorAll(
      `.${styles.menuContainer} a, .${styles.menuContainer} button`
    );
    links.forEach((link, index) => {
      if (link instanceof HTMLElement) {
        link.style.transitionDelay = `${index * 50}ms`;
      }
    });

    const letters = document.querySelectorAll(`.${styles.flyingLetter}`);
    letters.forEach((letter, index) => {
      if (letter instanceof HTMLElement) {
        letter.style.setProperty("--letter-index", index.toString());
      }
    });
  }, [isMenuOpen]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>PennKraft LLC</div>
      <Hamburger isOpen={isMenuOpen} onClick={toggleMenu} />
      <div
        className={`${styles.menuContainer} ${
          isMenuOpen ? styles.menuOpen : ""
        }`}
      >
        <nav>
          <ul>
            {menuItems.map((item) => (
              <li key={item}>
                <NavLink
                  to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {wrapLetters(item)}
                </NavLink>
              </li>
            ))}
            <li>
              <button
                className={styles.signInButton}
                onClick={handleSignInClick}
              >
                {wrapLetters(isAuthenticated ? "Sign Out" : "Sign In")}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
