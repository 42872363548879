import React, { useState, useEffect } from "react";
import styles from "./DocumentManagement.module.css";

interface Document {
  id: string;
  type: "photo" | "video";
  title: string;
  url: string;
}

const DocumentManagement: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [newDocument, setNewDocument] = useState<Partial<Document>>({
    type: "photo",
    title: "",
    url: "",
  });

  useEffect(() => {
    // Fetch documents from API in a real application
    const mockDocuments: Document[] = [
      { id: "1", type: "photo", title: "Photo 1", url: "/path/to/photo1.jpg" },
      { id: "2", type: "video", title: "Video 1", url: "/path/to/video1.mp4" },
    ];
    setDocuments(mockDocuments);
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setNewDocument({ ...newDocument, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // In a real application, you would send this data to your API
    const newDoc: Document = {
      ...(newDocument as Document),
      id: Date.now().toString(),
    };
    setDocuments([...documents, newDoc]);
    setNewDocument({ type: "photo", title: "", url: "" });
  };

  return (
    <div className={styles.management}>
      <h1>Document Management</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <select
          name="type"
          value={newDocument.type}
          onChange={handleInputChange}
        >
          <option value="photo">Photo</option>
          <option value="video">Video</option>
        </select>
        <input
          type="text"
          name="title"
          value={newDocument.title}
          onChange={handleInputChange}
          placeholder="Title"
          required
        />
        <input
          type="text"
          name="url"
          value={newDocument.url}
          onChange={handleInputChange}
          placeholder="URL"
          required
        />
        <button type="submit">Add Document</button>
      </form>
      <ul className={styles.documentList}>
        {documents.map((doc) => (
          <li key={doc.id}>
            {doc.title} ({doc.type})
            <button
              onClick={() => {
                /* Implement delete functionality */
              }}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentManagement;
