import React, { useState } from "react";
import PageContainer from "../components/PageContainer";
import styles from "./PhotoVideoLibrary.module.css";
import videoList from "../videoList";

const PhotoVideoLibrary: React.FC = () => {
  const [selectedVideo, setSelectedVideo] = useState<
    (typeof videoList)[0] | null
  >(null);

  // Function to generate a random color for the banner
  const generateBannerColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <PageContainer>
      <h1 className={styles.title}>Video Library</h1>
      <div className={styles.videoGrid}>
        {videoList.map((video) => (
          <div
            key={video.id}
            className={styles.videoItem}
            onClick={() => setSelectedVideo(video)}
          >
            <div
              className={styles.thumbnail}
              style={{ backgroundColor: generateBannerColor() }}
            >
              <span className={styles.playIcon}>▶</span>
            </div>
            <p className={styles.videoTitle}>{video.title}</p>
          </div>
        ))}
      </div>
      {selectedVideo && (
        <div className={styles.videoPlayer}>
          <h2>{selectedVideo.title}</h2>
          <video controls src={selectedVideo.src} className={styles.video}>
            Your browser does not support the video tag.
          </video>
          <button
            onClick={() => setSelectedVideo(null)}
            className={styles.closeButton}
          >
            Close
          </button>
        </div>
      )}
    </PageContainer>
  );
};

export default PhotoVideoLibrary;
