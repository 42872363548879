import React from "react";
import styles from "./Hero.module.css";

const Hero: React.FC = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.overlay}></div>
      <div className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1>Welcome to PennKraft LLC</h1>
          <p>Innovative Solutions for Modern Challenges</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
