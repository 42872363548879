
interface Video {
  id: string;
  title: string;
  src: string;
}

const videoList: Video[] = [
  {
    "id": "1",
    "title": "Video 0843",
    "src": "/videos/DJI_0843.MP4"
  },
  {
    "id": "2",
    "title": "Video 0844",
    "src": "/videos/DJI_0844.MP4"
  },
  {
    "id": "3",
    "title": "Video 0845",
    "src": "/videos/DJI_0845.MP4"
  },
  {
    "id": "4",
    "title": "Video 0846",
    "src": "/videos/DJI_0846.MP4"
  },
  {
    "id": "5",
    "title": "Video 0847",
    "src": "/videos/DJI_0847.MP4"
  },
  {
    "id": "6",
    "title": "Video 0848",
    "src": "/videos/DJI_0848.MP4"
  },
  {
    "id": "7",
    "title": "Video 0849",
    "src": "/videos/DJI_0849.MP4"
  },
  {
    "id": "8",
    "title": "Video 0850",
    "src": "/videos/DJI_0850.MP4"
  },
  {
    "id": "9",
    "title": "Video 0851",
    "src": "/videos/DJI_0851.MP4"
  }
];

export default videoList;
